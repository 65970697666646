var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"msp-tabella-risultato"},[_c('v-data-table',{staticClass:"striped elevation-1",attrs:{"headers":_vm.formattedHeaders,"items":_vm.formattedData,"items-per-page":5,"mobile-breakpoint":0,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([_vm._l((_vm.formattedHeaders),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,staticClass:"header-multiline",domProps:{"innerHTML":_vm._s(_vm.renderHeader(header))}})]},proxy:true}}),{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[_vm._v(_vm._s(_vm.renderItem(item, "data")))])]}},{key:"item.risultato",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"result"},[_c('span',{staticClass:"badge-record",domProps:{"innerHTML":_vm._s(_vm.renderBest(item))}}),_vm._v(_vm._s(_vm.renderItem(item, "risultato")))])]}},{key:"item.passo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"detail"},[_vm._v(_vm._s(_vm.renderItem(item, "passo")))])]}},{key:"item.wattkg",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"detail"},[_vm._v(_vm._s(_vm.renderItem(item, "wattkg")))])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.item)?_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.stringSegmento))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.item.stringRisultato)+" ")]),_c('v-card-text',[(_vm.item.risultato)?[_c('div',{staticClass:"data"},[_c('span',{staticClass:"badge-record",domProps:{"innerHTML":_vm._s(_vm.renderBest(_vm.item))}})])]:_vm._e(),(_vm.item.passo)?[_c('div',{staticClass:"data"},[_vm._v(_vm._s(_vm.renderItem(_vm.item,"passo")))])]:_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate(_vm.item.data))+" ")],2),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.openModal(_vm.item)}}},[_vm._v("Attività "),_c('i',{staticClass:"msp-icon-plan"})]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.item)}}},[_vm._v(" Elimina "),_c('v-icon',[_vm._v("mdi-delete")])],1),_c('msp-confirm',{ref:"confirmDelete"})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }