<template>
    <div class="msp-analisi-record" >
        <v-app-bar app >
            <v-btn icon @click="dismissModal()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-toolbar-title>Analisi dei record {{renderOptionSegmento(segmento)}}</v-toolbar-title>


        </v-app-bar>
        <v-card><v-card-text>
                <v-select
                    :items="filteredSports"
                    item-text="label"
                    v-model="sport"
                    @change="changeSport"
                    label="Sport"
                ></v-select>
                <div class="segmento-container hide-scrollbar">
                    <div class="segmento-chips">
                        <v-chip
                            v-for="item in listaSegmento"
                            :class="classSegmento(item)"
                            :key="item.segmento"
                            :data-segmento="item.segmento"
                            @click="selectSegmento(item)"
                            class="segmento-chip mr-1"
                        >{{renderOptionSegmento(item)}}</v-chip>
                    </div>
                </div>
                <v-select
                    :items="listaPeriodi"
                    item-text="text"
                    item-value="value"
                    v-model="periodo"
                    @change="changePeriodo"
                    label="Scegli un periodo"
                ></v-select>
        </v-card-text></v-card>
        <div class="best-records">
            <MspBestRecord
                v-for="(bestRecord, index) in best"
                :key ="index"
                :data="bestRecord"
                :tipo="tipo"
                :posizione="index"
            ></MspBestRecord>
        </div>
        <msp-grafico-record 
            :data="filteredRecords"
            :options="opzioniGrafico"
        ></msp-grafico-record>
        <msp-tabella-risultato 
            :data="filteredRecords"
            :best="best"
            :headers="headers"
            :segmento="segmento"
            @delete="deleteRecordAndUpdate"
        ></msp-tabella-risultato>
        <msp-app-footer></msp-app-footer>
    </div>
</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import mspAppFooter from "@components/MspAppFooter.vue";
import MspGraficoRecord from "@components/MspGraficoRecord.vue";
import MspTabellaRisultato from "@components/MspTabellaRisultato.vue";
import MspBestRecord from "@components/MspBestRecord.vue";
import api, {URLparams} from "@mixins/api.js";
import env from "@mixins/environment.js"
import { mapGetters } from 'vuex'
import _funzioni from "@src/js/msp/funzioni.js"
import _record from "@mixins/record-segmenti.js"

_record.aggiungiAnniListaPeriodi(_record.listaPeriodi, 4);
const component = {
    mixins: [
        env,
    ],
    components: {
        mspAppBar,
        mspAppFooter,
        MspGraficoRecord,
        MspTabellaRisultato,
        MspBestRecord,
    },
    data() {
        return {
            top10: false,
            availableSports: [],
            sports: _record.sports,
            tipoRecord: null,
            segmento: null,
            tipo: "",
            periodo: "ALL",
            sport:null,
            listaSegmento: [ ],
            listaPeriodi: _record.listaPeriodi,
            best: [],
            all: [],
            top: [],
            opzioniGrafico: {},
            headers: _record.headers,
        }
    },
    watch: {
        atleta: function () {
            this.resetDefault();
        },
    },
    computed: {
        ...mapGetters('atleti', [
            "atleta",
            "atletaId",
        ]),
        ...mapGetters('utente', [
            "options",
        ]),
        ...mapGetters('app', [
            "headerHeight",
        ]),
        styleSticky: function () {
            if (this.headerHeight) {
                return `--top: ${this.headerHeight}px`;
            }
            return "";
        },
        filteredSports: function () {
            return _record.sports.filter(el=>this.availableSports.includes(el.sport_id));
        },
        filteredRecords: function () {
            if (!this.top10) return this.all;
            return this.getTop10(this.all);
        },
        topTen: function () {
        },
    },
    
    mounted: async function () {
        this.loadAvailableSports();
        await this.loadListaSegmento();

        if (this.$route.query && this.$route.query.segmento) {
            this.sport = this.$route.query.sport;
            await this.updateSport();
            await this.loadListaSegmento();
            const segmento = this.getSegmento(this.$route.query.segmento);
            if (segmento) {
                this.selectSegmento(segmento);
            }
        } else {
            this.resetDefault();

        }
    },
    methods: {
        getSegmento: function (valore) {
            if (!(this.listaSegmento && this.listaSegmento.length)) return false;
            let segmento = this.listaSegmento.find(el=>el.segmento == valore);
            return segmento;

        },
        classSegmento: function (segmento) {
            if (!this.segmento) return "";
            const classItem =  (segmento.segmento === this.segmento.segmento) ? "active" : "";
            return classItem;
        },
        getTop10: function (records) {
            if (!this.sport) return [];
            const sport = _record.getSport(this.sport);
            let sorted;
            if (sport.order === "desc") {
                sorted = records.sort(this.sortByRisultatoDesc);
            } else {
                sorted = records.sort(this.sortByRisultatoAsc);
            }
            let filtered = [];
            for (let i = 0; (i<10 && i < sorted.length-1); i++) {
                filtered.push(sorted[i]);
            }
            return filtered;
        },
        sortByRisultatoDesc: function (a,b) {
            return +a.risultato > +b.risultato ? -1 : 1;
        },
        sortByDate: function (a,b) {
            return new Date(a.data) < new Date (b.data) ? -1 : 1;
        },

        sortByRisultatoAsc: function (a,b) {
            return +a.risultato < +b.risultato ? -1 : 1;
        },

        renderOptionSegmento: function (option) {
            if (!option) return "";
            return option.stringValue;
        },
        resetDefault: async function () {
            await this.loadAvailableSports();
            await this.setSportDefault()
        },
        dismissModal () {
            this.$store.dispatch("calendario/setDialog", false);
            this.$router.go(-1);
        },
        formatRisultato: _record.formatRisultato,
        makeRecord: function (data) {
            const dataSegmento = data.map(el=>{
                return {
                    ...el,
                    segmento: this.segmento.segmento,
                }
            });
            return _record.makeRecord(dataSegmento);
        },
        getOptionSportId: async function () {
            let record_sport = await this.$store.dispatch("utente/getOption", 'record-sport');
            return record_sport;
        },
        loadAvailableSports: async function () {
            const records = await this.loadCountRecord();
            if (!(records && records.length)) {
                this.$router.push("/record");
            }
            let sportIds = records.map(el=>el.sport_id);
            this.availableSports = sportIds;
        },
        getDefaultSportId: async function () {
            let sport_id;
            if (this.availableSports.length == 1) {
                sport_id = this.availableSports[0];
            } else {
                const preference = [2,1];
                let selectPreference = preference.find(pre=>this.availableSports.includes(pre));
                if (selectPreference) {
                    sport_id = selectPreference;
                } else {
                    sport_id = this.availableSports[0];
                }
            }
            return sport_id;
        },
        setSportDefault: async function () {
            let sport_id = await this.getOptionSportId();
            if (!sport_id) {
                sport_id = await this.getDefaultSportId();
            }
            if (!sport_id) return;
            const sport = _record.getSportById(sport_id);
            if (!sport) return;
            this.sport = sport.value;
            this.changeSport();

        },
        loadCountRecord: function () {
            if (!this.atletaId) return;
            return _record.loadCountRecord(this.atletaId);
        },
        setDefaultSegmento: function () {
            if (!(this.listaSegmento && this.listaSegmento.length)) return;
            const segmento = this.listaSegmento[0];
            this.selectSegmento(segmento);
        },
        getSportIdFromSport: function (sport_codice) {
            const sport = this.sports.find(el=>el.value === sport_codice);
            if (!sport) return false;
            return sport.sport_id;
        },
        makeListaSegmento: function (data) {
            return data.map(this.makeItemSegmento);
        },
        makeItemSegmento: function (item) {
            const formatted = {
                ...item,
            };
            if (this.sport==='PODISMO') {
            const ex = _record.formatGrandezzaDistanza(item.segmento);
            if (ex.um) {
                formatted.stringValue = `${ex.value} ${ex.um}`;
            } else {
                formatted.stringValue = `${ex.value}`;
            }
            } else {
                formatted.stringValue = _funzioni.sec2hms(item.segmento, (item.segmento < 3600));
            }
            return formatted;
        },
        loadListaSegmento: function () {
            if (!this.sport) {
                this.listaSegmento = [];
                return Promise.resolve(false);
            }
            let sport_id = this.getSportIdFromSport(this.sport);
            let data = {
                lista_segmenti: 1,
                atleta_id: this.atletaId,
                sport_id: sport_id,
            }
            let params = URLparams(data);
            let url = this.api_base_url+`/api/v2/record.php?${params}`;

            return new Promise((resolve,reject)=>{
                api(url,'get')
                    .then(
                        (result)=>{

                            if (result.success) {
                                this.listaSegmento = this.makeListaSegmento(result.data);
                                resolve(result.data);
                            } else {
                                this.listaSegmento = [];
                                resolve(null);
                            }
                        },
                        (error)=>{
                            reject(error);
                        }
                    )
            });
        },
        deleteRecordAndUpdate: async function (id) {
            await this.deleteRecord(id);
            this.updateListaSegmento();
            this.loadRecord();
        },
        deleteRecord: function (id) {
            let data = {
                id: id,
            }
            let params = URLparams(data);
            let url = this.api_base_url+`/api/v2/record.php?${params}`;

            return new Promise((resolve,reject)=>{
                api(url,'delete')
                    .then(
                        (result)=>{
                            if (result.success) {
                                resolve(true);
                            } else {
                                resolve(null);
                            }
                        },
                        (error)=>{
                            reject(error);
                        }
                    )
            });
        },
        changeSport: async function () {
            await this.reset();
            await this.updateSport();
            await this.updateListaSegmento();
        },
        updateSport: function () {
            const sport = _record.getSport(this.sport);
            this.tipoRecord = sport.tipo_record;
            this.tipo = sport.tipo;
            this.headers = _record.headersByTipoTabellaRecord('analisi',this.tipoRecord);
        },
        segmentoExists: function (segmento) {
            if (!segmento) return false;
            let found = this.listaSegmento.find((el)=>(el.sport === segmento.sport && el.segmento === segmento.segmento));
            return (found) ? true : false;
        },
        isSegmentoValid: function (segmento) {
            if (!segmento) return false;
            const found = this.listaSegmento.find(el=>el.segmento == segmento.valore);
            return found;
        },
        updateListaSegmento: async function () {
            await this.loadListaSegmento();
            if (!this.isSegmentoValid(this.segmento)) {
                this.setDefaultSegmento();
            }
        },
        centerTabSegmento: function () {
            if (!this.segmento) return;
            const segmento = this.segmento.segmento;
            const tab = this.$el.querySelector(`.segmento-chips [data-segmento='${segmento}']`);
            if (!tab) return;
            this.centerTab(tab);
        },
        centerTab: function (tab) {
            this.$forceNextTick(()=>{
                const margin = 20;
                const container = this.$el.querySelector(".segmento-container")
                const tb = tab.getBoundingClientRect();
                const cb = container.getBoundingClientRect();
                const outRight = (tb.right - cb.right);
                const outLeft = (tb.left - cb.left);
                if (outRight > 0) {
                    container.scrollLeft += outRight+margin;
                }
                if (outLeft < 0) {
                    container.scrollLeft += outLeft - margin;
                }
            },0);
        },
        selectSegmento: function (segmento) {
            this.segmento = segmento;
            this.centerTabSegmento();
            this.loadRecord();
        },
        changePeriodo: function () {
            this.reset();
            this.loadRecord();
        },
        reset: function () {
            this.all = [];
            this.top = [];
            this.best = [];
        },
        loadRecord: function () {
            this.reset();
            this.loading = true;
            const atleta_id = this.atleta.id;
            const tipo_record = this.tipoRecord;
            const segmento = this.segmento.segmento;
            const periodo = this.periodo;
            let data = {
                atleta_id: atleta_id,
                tipo_record: tipo_record,
                periodo: periodo,
                segmento: segmento,
            }
            let params = URLparams(data);
            let url = this.api_base_url+`/api/v2/record.php?${params}`

            return new Promise((resolve,reject)=>{
                api(url,'get')
                    .then((result)=>{
                        if (result.success) {
                            this.loading = false;
                            this.all = this.makeRecord(result.data.top);
                            this.best = result.data.best;
                            this.opzioniGrafico.best = this.best.map((el,i)=>({
                                date: el.data,
                                value: el.risultato,
                                color: _record.configBest[i].color,
                                size: 1.5,
                            }));
                            resolve(result);
                        } else {
                            
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });
        },
    }
};
export default component;
</script>

<style lang="scss">
.msp-analisi-record {
    .v-chip.active {
        background: var(--col-msp-chiaro) !important;
    }
    .v-slide-group__prev--disabled {
        display: none !important;
    }
    .segmento-container {
        overflow-x:auto;
        scroll-behavior: smooth;
    }
    .segmento-chips {
        display: flex;
        flex-wrap:nowrap;
    }
    .segmento-chip {
        flex-shrink: 0;
        flex-grow:1;
    }
    .best-records {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
    }
    .striped tr:nth-child(odd)  td {
        background: var(--col-grigio-chiarissimo);
    }
}

</style>
