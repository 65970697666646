<template> <v-card
        :color="color" 
        class="msp-best-record">
        <v-card-text>
            <div class="best-record__posizione">{{classifica}}</div>
            <div class="best-record__risultato">{{renderRisultato}}</div>
            <div v-if="renderDettaglio" class="best-record__dettaglio">{{renderDettaglio}}</div>
            <div class="best-record__data">{{renderData}}</div>
        </v-card-text>
    </v-card>
</template>
<script type="module">
import _funzioni from "../../../js/msp/funzioni.js"

const badges = [
    {
        color: '#FFD700',
        classifica: '1st',
    },
    {
        color: '#C0C0C0',
        classifica: '2nd',
    },
    {
        color: '#CD7F32',
        classifica: '3th',
    },
];

const component = {
    props: {
        record: {
            type: [String, Number],
        },
        data: {
            type: Object
        },
        posizione: {
            type: Number,
            default: 0,
        },
        tipo: {
            type: String,
            default: 'speed',
        },
    },
    mixins: [
    ],
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        badge: function () {
            if (this.posizione > badges.length -1) return null;
            const badge = badges[this.posizione];
            return badge;
        },
        color: function () {
            const badge = this.badge;
            if (!badge) return "";
            return badge.color;
        },
        classifica: function () {
            const badge = this.badge;
            if (!badge) return "";
            return badge.classifica;
        },
        renderRisultato: function () {
            let risultato;
            if (this.tipo === 'speed') {
                risultato = _funzioni.sec2hms(this.data.risultato, this.data.risultato<3600);
            } else {
                risultato = `${this.data.risultato} watt`;
            }
            return risultato;
        },
        renderDettaglio: function () {
            if (!this.record) return false;
            let dettaglio;
            if (this.tipo === 'speed') {
                const passo = _funzioni.getPassoKm(this.data.risultato, this.record);
                const formatPasso = _funzioni.sec2hms(passo,true);
                dettaglio =  `(${formatPasso}/km)`;
            } else {
                dettaglio =  `(${this.data.dettaglio} ${this.data.note_dettaglio})`;
            }
            return dettaglio;
        },
        renderData: function () {
            return _funzioni.formatDate(this.data.data);
        },
    },
    mounted() {
    },
    methods: {
    }
};
export default component;
</script>

<style lang="scss">
    .msp-best-record {
        .best-record {
            &__risultato {
                font-size: 1.3em;
                font-weight: bold;
            }
            &__data {
                font-size: 0.8em;
            }
        }
    }
</style>
